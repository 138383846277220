<template>

  <Workspaces />

</template>

<script>

import Workspaces from "@/components/view/Workspaces";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
Workspaces,
 
  },
  metaInfo: {
    title: "Kantoorpanden | Jouwbedrijfsmakelaar aanbod ",
    metaInfo:'Op zoek naar een bedrijfspand Of wil je jouw bedrijfspand verhuren? Jouwbedrijfsmakelaar Nederland. ',
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>