<template>
  <div>
    <img src= @/assets/pages.png height="300" width="100%" />
    <v-container>
      <v-card class="style" elevation="2" outlined tile>
        <v-row class="space custom-row">
          <v-col cols="12" sm="4">
            <v-select
              v-model="filter.item"
              :items="items"
              chips
              label="Wat"
              multiple
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filter.type"
              :items="types"
              chips
              label="koop/huur"
              multiple
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="filter.available"
              :items="availables"
              chips
              label="Beschikbaarheid"
              multiple
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-button variant="dark" @click="sidebarOpen = true" v-show="mobile"
      ><v-icon
        icon="filter-square-fill"
        animation="throb"
        font-scale="1"
        aria-hidden="true"
      ></v-icon
      >Filter</v-button
    >

    <v-container>
      <v-row no-gutters class="buildings">
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="building in buildings"
          :key="building.house_id"
        >
          <v-card class="mx-auto" max-width="400">
            <v-img
              :src="
                building.images[0]
                  ? `https://back-end.mcmakelaardij.nl/static/${building.images[0].filename}`
                  : require('@/assets/no-house-image.png')
              "
              img-alt="Image"
              img-top
              tag="article"
              class="h-100"
            >
            </v-img>
            <v-card-title> {{ building.adres }} </v-card-title>

            <v-card-text class="text--primary">
              <div>{{ building.prijs }}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="orange" block :to="`/Single/${building.house_id}`">
                Bekijk dit pand</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data: () => {
      return {
        mobile: false,
        sidebarOpen: false,

        buildings: [],
        items: [
          'Bedrijfsruimte',
          'Belegging',
          'Bouwgrond',
          'Horeca',
          'Kantoorruimte',
          'winkelruimte',
          'overig',
        ],
        types: ['Koop', 'Huur'],
        availables: ['Beschikbaar', 'Verhuurd', 'Verkocht'],

        filter: {
          item: '',
          type: '',
          available: '',
        },
      };
    },
    async mounted() {
      await this.getBuildings();
    },
    methods: {
      async getBuildings() {
        await axios
          .get('https://back-end.mcmakelaardij.nl/api/company')
          .then(response => (this.buildings = response.data));
      },
    },
  };
</script>

<style scoped>
  .buildings {
    margin-top: 30px;
    margin-bottom: 30px;
    margin: 5px;
  }
  .space {
    margin-top: 5px;
  }
  .style {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
